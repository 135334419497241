<template>
  <div>
    <h1>EZ Tag</h1>
    <p>Click a row to work the item.</p>
    <div class="panel">
      <div class="flex-container">
        <v-text-field
          @input="vin = vin.toUpperCase()"
          v-model="vin"
          autofocus
          clearable
          label="VIN"
        />
        <v-text-field v-model="dealerID" clearable label="Dealer ID" />
        <v-text-field v-model="batchID" clearable label="Batch ID" />
      </div>
      <div class="bootstrap">
        <b-table
          sort-by="batchID"
          sticky-header="65vh"
          :no-border-collapse="true"
          responsive="sm"
          hover
          selectable
          select-mode="single"
          :show-empty="true"
          @row-clicked="(item, index) => tableRowClick(item)"
          :empty-text="transactionRequestPending ? 'Loading...' : 'No Results'"
          :fields="fields"
          head-variant="light"
          sort-icon-left
          :items="filteredTransactions"
        >
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import dayjs from "dayjs";
export default {
  mixins: [vehicleFunctions],
  data() {
    return {
      vin: "",
      dealerID: "",
      batchID: "",
      transactions: [],
      transactionJSON: {},
      transactionRequestPending: false,
      filterInputs: {
        batchID: "",
        dealerID: "",
        vin: ""
      },
      fields: [
        {
          formatter(value) {
            return dayjs(value).format("MM/DD/YY");
          },
          key: "transactionDate",
          sortable: true
        },
        {
          key: "vin",
          sortable: false
        },
        {
          key: "plateNo",
          sortable: false
        },
        {
          key: "plateClassCode",
          sortable: true
        },
        {
          key: "customerName",
          sortable: true
        },
        {
          key: "transactionJSON.dealerID",
          label: "Dealer ID",
          sortable: true
        },
        {
          key: "transactionJSON.batchID",
          label: "Batch ID",
          sortable: false
        }
      ]
    };
  },
  methods: {
    async tableRowClick(item) {
      try {
        await this.$api.batchTransactionSearch(item.transactionID);
        const tandr = this.reformatTandrObjectforTransactionScreen(
          item.transactionJSON
        );
        this.$store.commit("processObj", tandr);
        this.$store.commit("transactionType", "EZ Tag");
        this.$router.push({
          name: "Transaction",
          params: {
            vin: tandr.vehicle.vin,
            make: tandr.vehicle.makeCode,
            year: tandr.vehicle.modelYear,
            menuIsShown: false
          }
        });
      } catch (e) {
        console.error(e);
        return;
      }
    },
    async getTransactions() {
      this.transactionRequestPending = true;

      try {
        this.transactions = await this.$api.getEZTagTransactions();
      } finally {
        this.transactionRequestPending = false;
      }
      if (!this.transactions || this.transactions.length === 0) return;
      this.transactions.forEach(transaction => {
        transaction.transactionJSON = JSON.parse(transaction.transactionJSON);
      });
    }
  },
  computed: {
    filteredTransactions() {
      if (!this.transactions || this.transactions.length === 0) return;
      let filteredTransactions = [];

      for (const [key] of Object.entries(this.filterInputs)) {
        Object.assign(this.filterInputs, {
          [key]: this[key] || ""
        });
      }

      let filterFieldsCount = 0;

      this.transactions.forEach(transaction => {
        let acceptableTransaction = true;
        for (const [key, value] of Object.entries(this.filterInputs)) {
          if (![null, undefined, ""].includes(value)) {
            filterFieldsCount++;
            if (
              (key !== "vin" &&
                !transaction.transactionJSON[key]
                  .toString()
                  .toUpperCase()
                  .includes(value.toUpperCase())) ||
              (key === "vin" &&
                !transaction[key].toUpperCase().includes(value.toUpperCase()))
            ) {
              acceptableTransaction = false;
            }
          }
        }
        if (acceptableTransaction) {
          filteredTransactions.push(transaction);
        }
      });

      if (filteredTransactions.length === 0 && filterFieldsCount === 0) {
        filteredTransactions = this.transactions;
      }
      return filteredTransactions;
    }
  },
  created() {
    this.getTransactions();
  }
};
</script>

<style lang="scss">
.flex-container {
  display: flex;
}
</style>
